/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem';
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub';
import {useAuth} from '../../../../../app/modules/auth';
import {useEffect, useState} from 'react';
import Pusher from 'pusher-js';
import {useMasterLayourContext} from '../../../core/MasterLayourContext';

const SidebarMenuMain = () => {
  const {setFlashNotification} = useMasterLayourContext();
  const {initAppData, auth} = useAuth();

  const [hasDealNotification, setHasDealNotification] = useState(false);
  const [hasTaskNotification, setHasTaskNotification] = useState(false);

  const pusherApiKey = process.env.REACT_APP_PUSHER_API_KEY ?? '';
  const pusherApiCluster = process.env.REACT_APP_PUSHER_API_CLUSTER ?? '';

  useEffect(() => {
    const pusher = new Pusher(pusherApiKey, {
      cluster: pusherApiCluster,
      forceTLS: true,
    });
    const channel = pusher.subscribe(`staff-${auth?.email}`);
    channel.bind('task-updates', (data: any) => {
      setFlashNotification(data.message);
      setHasTaskNotification(true);
    });
    channel.bind('deal-updates', (data: any) => {
      setFlashNotification(data.message);

      setHasDealNotification(true);
    });
  }, [Pusher, setFlashNotification]);

  let menuClient,
    menuDeal,
    menuTask,
    menuInvoice,
    menuRefund,
    menuVoucher,
    menuPromotion,
    menuReminder,
    menuExchangeRate,
    menuNotification,
    menuPaymentPlan,
    menuUser,
    menuManageOrder,
    menuManageWebsite;

  const allowedOperations = initAppData?.data.allowedOperations;

  if (allowedOperations?.client?.read && allowedOperations?.client?.read === true) {
    menuClient = (
      <SidebarMenuItem
        to='/apps/clients'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Clients'
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.deal?.read && allowedOperations?.deal?.read === true) {
    menuDeal = (
      <SidebarMenuItem
        to='/apps/deals'
        icon='/media/icons/duotune/communication/com007.svg'
        title='Deals'
        hasNotification={hasDealNotification}
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.task?.read && allowedOperations?.task?.read === true) {
    menuTask = (
      <SidebarMenuItem
        to='/apps/tasks'
        icon='/media/icons/duotune/communication/com007.svg'
        title='Task'
        hasNotification={hasTaskNotification}
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.reminder?.read && allowedOperations?.reminder?.read === true) {
    menuReminder = (
      <SidebarMenuItem
        to='/apps/reminders'
        icon='/media/icons/duotune/communication/com007.svg'
        title='Reminders'
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.exchange_rate?.read && allowedOperations?.exchange_rate?.read === true) {
    menuExchangeRate = (
      <SidebarMenuItem
        to='/apps/exchange-rates'
        icon='/media/icons/duotune/finance/fin010.svg'
        title='Exchange Rates'
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.invoice?.read && allowedOperations?.invoice?.read === true) {
    menuInvoice = (
      <SidebarMenuItem
        to='/apps/invoices'
        icon='/media/icons/duotune/finance/fin008.svg'
        title='Invoices'
        fontIcon='bi-app-indicator'
      />
    );
  }

  
  if (allowedOperations?.payment_plan?.read && allowedOperations?.payment_plan?.read === true) {
    menuPaymentPlan = (
      <SidebarMenuItem
        to='/apps/payment-plan-orders'
        icon='/media/icons/duotune/finance/fin010.svg'
        title='Payment Plan'
        fontIcon='bi-app-indicator'
      />
    );
  }

  if (allowedOperations?.refund?.read && allowedOperations?.refund?.read === true) {
    menuRefund = (
      <SidebarMenuItem
        to='/apps/refunds'
        icon='/media/icons/duotune/finance/fin005.svg'
        title='Refunds'
        fontIcon='bi-app-indicator'
      />
    );
  }

  console.log('allowedOperations :: ', allowedOperations);

  if (allowedOperations?.order?.read && allowedOperations?.order?.read === true) {
    menuManageOrder = (
      <SidebarMenuItemWithSub
        to='/#'
        title='Order'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/ecommerce/ecm001.svg'
      >
        <SidebarMenuItem
          to='/apps/order/flight-deal-orders'
          title='Flight Deal Orders'
          hasBullet={true}
        />

        <SidebarMenuItem to='/apps/order/tour-orders' title='Tour Orders' hasBullet={true} />
      </SidebarMenuItemWithSub>
    );
  }
  if (allowedOperations?.website?.read && allowedOperations?.website?.read === true) {
    menuManageWebsite = (
      <SidebarMenuItemWithSub
        to='/#'
        title='Manage Website'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItem to='/apps/website/flight-deals' title='Flight Deals' hasBullet={true} />
        <SidebarMenuItem to='/apps/website/tours' title='Tours' hasBullet={true} />

        <SidebarMenuItem to='/apps/website/blog-articles' title='Blog Articles' hasBullet={true} />
      </SidebarMenuItemWithSub>
    );
  }

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      {menuClient}
      {menuDeal}
      {menuTask}
      {menuRefund}
      {menuVoucher}
      {menuPromotion}
      {menuReminder}
      {menuExchangeRate}
      {menuInvoice}
      {menuNotification}
      {menuPaymentPlan}
      {menuUser}
      {menuManageOrder}
      {menuManageWebsite}
    </>
  );
};

export {SidebarMenuMain};
